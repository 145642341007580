import React, { useState } from "react";
import { error, success } from "../components/alertMessage";
import Ckeditor from "../components/ckeditor";
import InsertActHook from "../hooks/acts";

function Acts() {
  const [valueState, setValue] = useState();
  const handleInput = (name, value) => {
    setValue({ ...valueState, [name]: value });
  };
  const InsertAct = async (event) => {
    event.preventDefault();
    if (
      valueState.act_description !== undefined &&
      valueState.act_description !== ""
    ) {
      const result = await InsertActHook(valueState);
      console.log(result);
      if (result === 200) {
        success("Data added successfully");
        window.location.reload();
      } else error("Something went wrong please try again later");
    } else error("Description is missing");
  };
  return (
    <div>
      <h2 className="text-center p-4 text-light mb-5 bg-primary">Enter Acts</h2>
      <form onSubmit={InsertAct}>
        <div className="row my-5">
          <div className="col">
            <label className="form-label h5">Act Name: </label>
            <input
              type="text"
              className="form-control"
              onChange={(event) => handleInput("act_name", event.target.value)}
              required
            />
          </div>
          <div className="col">
            <label className="form-label h5">Full form of act name: </label>
            <input
              type="text"
              className="form-control"
              onChange={(event) =>
                handleInput("act_name_full_form", event.target.value)
              }
              required
            />
          </div>
        </div>
        <label className="form-label h5">Write About Act: </label>
        <Ckeditor getValue={(text) => handleInput("act_description", text)} />
        <div className="text-center my-5">
          <button className="btn btn-lg px-5 btn-outline-primary" type="submit">
            Add Act
          </button>
        </div>
      </form>
    </div>
  );
}

export default Acts;
