import axios from "axios";
const InsertActHook = (data) => {
  return new Promise((resolve) => {
    axios
      .post(`${process.env.REACT_APP_API_URL}insert_act`, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  });
};
export default InsertActHook;
