import axios from "axios";

const InsertSectionsHook = (data) => {
  return new Promise((resolve) => {
    axios
      .post(`${process.env.REACT_APP_API_URL}insert_section`, data)
      .then((response) => resolve(response.data))
      .catch((err) => console.log(err));
  });
};

const getChapters = (data) => {
  return new Promise((resolve) => {
    axios
      .post(`${process.env.REACT_APP_API_URL}get_chapters_by_acts`, data)
      .then((response) => resolve(response.data))
      .catch((err) => console.log(err));
  });
};

export { InsertSectionsHook, getChapters };
