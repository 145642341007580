import React, { useEffect, useState } from "react";
import { error, success } from "../components/alertMessage";
import Ckeditor from "../components/ckeditor";
import { getActs, InsertChapter } from "../hooks/chapters";

function Chapters() {
  const [valueState, setValue] = useState("");
  const [actList, setActList] = useState(null);
  useEffect(() => {
    (async () => {
      const result = await getActs();
      setActList(result);
      console.log("Act list", result);
    })();
  }, []);
  const InsertChapters = async (event) => {
    event.preventDefault();
    if (
      valueState.chapter_description !== undefined &&
      valueState.chapter_description !== ""
    ) {
      const result = await InsertChapter(valueState);
      if (result === 200) {
        success("Data added successfully");
        window.location.reload();
      } else error("Something went wrong please try again later");
    } else error("Description is missing");
  };
  const HandleInputFields = (key, value) => {
    setValue({ ...valueState, [key]: value });
  };
  return (
    <div>
      <h2 className="text-center p-4 text-light mb-5 bg-primary">
        Enter Chapters
      </h2>
      <form onSubmit={InsertChapters}>
        <div className="row my-5">
          <div className="col">
            <label className="form-label h5">Act List: </label>
            <select
              className="form-select"
              required
              onChange={(event) =>
                HandleInputFields("act_id", event.target.value)
              }
            >
              <option selected disabled value="">
                Select Act
              </option>
              {actList !== null
                ? actList.map((items) => (
                    <option value={items.act_id}>
                      {items.act_name} - {items.act_name_full_form}
                    </option>
                  ))
                : null}
            </select>
          </div>
          <div className="col">
            <label className="form-label h5">Chapter Number: </label>
            <input
              type="number"
              className="form-control"
              onChange={(event) =>
                HandleInputFields("chapter_number", event.target.value)
              }
              required
            />
          </div>
          <div className="col">
            <label className="form-label h5">Chapter Division: </label>
            <input
              type="text"
              className="form-control"
              onChange={(event) =>
                HandleInputFields("chapter_division", event.target.value)
              }
              required
            />
          </div>
        </div>
        <div className="my-5">
          <label className="form-label h5">Chapter Name: </label>
          <input
            type="text"
            className="form-control"
            onChange={(event) =>
              HandleInputFields("chapter_name", event.target.value)
            }
            required
          />
        </div>
        <label className="form-label h5">Write about this chapter: </label>
        <Ckeditor
          getValue={(text) => HandleInputFields("chapter_description", text)}
        />
        <div className="text-center my-5">
          <button className="btn btn-lg px-5 btn-outline-primary">
            Add Chapter
          </button>
        </div>
      </form>
    </div>
  );
}

export default Chapters;
