import logo from "./logo.svg";
import "./App.css";
import Chapters from "./features/chapters";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Acts from "./features/Acts";
import Sections from "./features/Sections";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function App() {
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <Router>
        <div className="container-fluid">
          <div className="row">
            <div className="col-2 side-bar border-end border-dark h-100">
              <ul className="list-group border-0 fw-bold fs-5">
                <li className="list-group-item bg-transparent border-0">
                  <Link to={"/act"} className="Link-tag">
                    Add Act
                  </Link>
                </li>
                <li className="list-group-item bg-transparent border-0">
                  <Link to={"/chapter"} className="Link-tag">
                    Add Chapter
                  </Link>
                </li>
                <li className="list-group-item bg-transparent border-0">
                  <Link to={"/section"} className="Link-tag">
                    Add sections
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-10">
              <Routes>
                <Route path="/act" element={<Acts />} />
                <Route path="/chapter" element={<Chapters />} />
                <Route path="/section" element={<Sections />} />
              </Routes>
            </div>
          </div>
        </div>
      </Router>
    </>
  );
}

export default App;
