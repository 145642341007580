import axios from "axios";

const InsertChapter = (data) => {
  return new Promise((resolve) => {
    axios
      .post(`${process.env.REACT_APP_API_URL}insert_chapter`, data)
      .then((response) => resolve(response.data))
      .catch((err) => console.log(err));
  });
};
const getActs = () => {
  return new Promise((resolve) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}get_acts`)
      .then((response) => resolve(response.data))
      .catch((err) => console.log(err));
  });
};
export { InsertChapter, getActs };
