import { keyboard } from "@testing-library/user-event/dist/keyboard";
import React, { useEffect, useState } from "react";
import { error, success } from "../components/alertMessage";
import Ckeditor from "../components/ckeditor";
import { getActs } from "../hooks/chapters";
import { getChapters, InsertSectionsHook } from "../hooks/sections";

function Sections() {
  const [valueState, setValue] = useState("");
  const [ActList, setActList] = useState();

  const [Chapter, setChapter] = useState();
  useEffect(() => {
    (async () => {
      const ActListResult = await getActs();
      setActList(ActListResult);
    })();
  }, []);
  const HandleInputValues = (key, value) => {
    if (key === "section_description") value = value.toString();
    setValue({ ...valueState, [key]: value });
  };
  const getChapterListAction = async (actId) => {
    const chapterListresult = await getChapters({ act_id: actId });
    setChapter(chapterListresult);
  };
  const InsertSections = async (event) => {
    event.preventDefault();
    if (
      valueState.section_description !== undefined &&
      valueState.section_description !== ""
    ) {
      console.log(valueState);
      const result = await InsertSectionsHook(valueState);
      if (result === 200) {
        window.location.reload();
        success("Data Inserted successfully");
      } else error("something went wrong please try again later");
    } else error("Description is missing");
  };
  return (
    <div>
      <h2 className="text-center p-4 text-light mb-5 bg-primary">
        Enter Sections
      </h2>
      <form onSubmit={InsertSections}>
        <div className="row my-5">
          <div className="col">
            <label className="form-label h5">Select Act:</label>
            <select
              className="form-select"
              onChange={(event) => {
                getChapterListAction(event.target.value);
                HandleInputValues("act_id", event.target.value);
                document.getElementById("chapter_list").value = "";
              }}
              required
            >
              <option selected disabled value="">
                Select Act
              </option>
              {ActList !== undefined ? (
                ActList.map((items) => (
                  <option value={items.act_id}>
                    {items.act_name} - {items.act_name_full_form}
                  </option>
                ))
              ) : (
                <></>
              )}
            </select>
          </div>
          <div className="col">
            <label className="form-label h5">Select Chapter:</label>
            <select
              className="form-select"
              required
              id="chapter_list"
              onInput={(event) =>
                HandleInputValues("chapter_id", event.target.value)
              }
            >
              <option selected disabled value="">
                Select Chapter
              </option>
              {Chapter !== undefined ? (
                Chapter.map((items) => (
                  <option value={items.chapter_id}>
                    {items.act_name} - {items.chapter_name}
                  </option>
                ))
              ) : (
                <></>
              )}
            </select>
          </div>
          <div className="col">
            <label className="form-label h5">Section Number</label>
            <input
              type="number"
              className="form-control"
              onChange={(event) =>
                HandleInputValues("section_number", event.target.value)
              }
              required
            />
          </div>
          <div className="col">
            <label className="form-label h5">Section Division</label>
            <input
              type="text"
              className="form-control"
              onChange={(event) =>
                HandleInputValues("section_division", event.target.value)
              }
              required
            />
          </div>
        </div>
        <div className="my-5">
          <label className="form-label h5">Section Name</label>
          <input
            type="text"
            className="form-control"
            onChange={(event) =>
              HandleInputValues("section_name", event.target.value)
            }
            required
          />
        </div>
        <label className="form-label h5">Write About Section</label>
        <Ckeditor
          getValue={(text) => HandleInputValues("section_description", text)}
        />
        <div className="text-center">
          <button className="btn btn-lg px-5 btn-outline-primary my-5">
            Add Section
          </button>
        </div>
      </form>
    </div>
  );
}

export default Sections;
